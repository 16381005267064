import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MainComponent } from './components/main/main.component';
import { IonCol, IonGrid, IonIcon, IonRow, IonSkeletonText } from '@ionic/angular/standalone';
import { arrowForwardOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { forkJoin } from 'rxjs';

import { CardShortsComponent } from '../../components/card-shorts/card-shorts.component';
import { ContentService } from '../../services/api/ws-content/content/content.service';
import { CardNewsComponent } from '../../components/card-news/card-news.component';
import { CommonModule, DatePipe } from '@angular/common';
import { CategoryService } from '../../services/api/ws-common/categories/category.service';
import { CardEventComponent } from '../../components/card-event/card-event.component';
import { EventService } from '../../services/api/ws-event/event/event.service';
import { LocationService } from '../../services/api/ws-common/location/location.service';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Content } from '../../models/Content.model';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [MainComponent, CardShortsComponent, IonIcon, CardNewsComponent, DatePipe, CommonModule, CardEventComponent, RouterModule, IonSkeletonText, TranslateModule, IonGrid, IonRow, IonCol],
  providers: [ContentService],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {

  @ViewChild('categoryBackground') categoryBackground!: ElementRef;
  videos!: Content[];
  news!: any[];
  categories!: any[];
  events!: any[];
  selectedCat!: any;
  isLoaded = false;
  themeMode!: string;
  categoryAnimationChanged = false;

  constructor(private contentService: ContentService, private categoryService: CategoryService, private eventService: EventService, private locationService: LocationService) {
    addIcons({ arrowForwardOutline });
  }

  ngOnInit(): void {
    this.themeMode = localStorage.getItem('mode') || 'light';
    forkJoin([
      this.contentService.getVideos(6),
      this.contentService.getNews(5),
      this.categoryService.getSubcategoryProductRelated(),
      this.eventService.getEvents(4)
    ]).subscribe({
      next: ([videos, news, categories, events]) => {

        this.videos = videos.data;
        this.news = news.data;
        this.categories = categories.data.filter((c: any) => !!c.children && c.children.length >= 1);
        this.events = events.data;
        this.selectedCat = this.categories[0];
        this.addCityToEvents();
      },
      error: (error) => {
        console.error('Error fetching data:', error);
      }
    });
    this.categoryAnimation();
  }

  private addCityToEvents() {
    forkJoin(
      this.events.map(event => {
        return this.locationService.getInfoByCityId(event.location.locationId);
      })
    ).subscribe(responses => {
      responses.forEach((res, index) => {
        this.events[index].cityTitle = res.data.cityTitle;
        this.events[index].stateShort = res.data.stateShort;
        this.events[index] = this.setEventType(this.events[index]);
        this.isLoaded = true;
      });
    });
  }

  selectCategory(item: any) {
    this.selectedCat = item;
    this.categoryBackgroundAnimation();
  }

  private categoryBackgroundAnimation() {
    this.categoryBackground.nativeElement.classList.remove('fade-in-image');
    this.categoryBackground.nativeElement.classList.add('display-none');
    setTimeout(() => this.categoryBackground.nativeElement.classList.add('fade-in-image'));
  }

  private setEventType(event: any) {
    if (event.typeEvent.includes('EventSintegra')) {
      let newEvent = {
        ...event,
        color: 'var(--color-secondary-50)',
        type: 'event.sintegra'
      };
      newEvent.typeEvent = 'sintegraevent';
      return newEvent;
    }
    if (event.typeEvent.includes('Congress')) {
      let newEvent = {
        ...event,
        color: 'var(--color-neutral-50)',
        type: 'event.congress'
      };
      newEvent.typeEvent = 'congresses';
      return newEvent;
    }
    if (event.typeEvent.includes('EventGeneral')) {
      let newEvent = {
        ...event,
        color: 'var(--color-tertiary-50)',
        type: 'event.general'
      };
      newEvent.typeEvent = 'generalevent';
      return newEvent;
    }
  }
  categoryAnimation() {
    this.categoryAnimationChanged = false;

    setTimeout(() => {
      this.categoryAnimationChanged = true;
    }, 200);
  }
}
