import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  darkTheme = false;
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.darkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  getDarkTheme(): string {
    return this.darkTheme ? 'dark' : 'light';
  }

  getTheme(): string | null {
    return localStorage.getItem('mode');
  }

  setTheme(theme: string) {
    this.renderer.setAttribute(document.body, 'data-theme', theme);
    localStorage.setItem('mode', theme);
    theme === 'light' ? this.darkTheme = false : this.darkTheme = true;
  }

  setThemeWindow(theme?: string) {
    if (theme) {
      this.renderer.setAttribute(document.body, 'data-theme', theme);
      localStorage.setItem('mode', theme);
      this.setTheme(theme);
      return
    }

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      localStorage.setItem('mode', 'dark');
      this.renderer.setAttribute(document.body, 'data-theme', 'dark');
      this.setTheme('dark');
    } else {
      localStorage.setItem('mode', 'light');
      this.renderer.setAttribute(document.body, 'data-theme', 'light');
      this.setTheme('light');
    }
  }
}
